<template>
  <div>
    <ul>
      <li class="label">Component</li>
      <li class="option">{{ component }}</li>
      <li class="label">Subcomponent</li>
      <li class="option">{{ subcomponent }}</li>
      <li class="label">Damage Type</li>
      <li class="option">{{ damageCode }}</li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "RenderDetails",
  props: ["component", "subcomponent", "damageCode"],
};
</script>

<style>
li {
  padding: 8px 2px;
  text-align: left;
}

li.label {
  font-size: 0.45em;
  color: lightsteelblue;
}

li.option {
  font-size: 0.5em;
  background-color: rgba(142, 191, 255, 0.096);
  font-weight: bold;
  padding-left: 15px;
}
</style>

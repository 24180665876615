<template>
  <div class="display-flex align-items-center flex-gap-14 flex-1">
    <a href="/">
      <img alt="logo.png" class="padding-12" src="../../assets/logo-new.png" />
    </a>
    <span class="main-title">Goodpack Operator Dashboard</span>
  </div>
</template>

<script>
export default {
  name: "CoreHeader",
};
</script>
<style>
.padding-12 {
  height: 50px;
}

.main-title {
  color: white;
  font-weight: bold;
  font-size: large;
}
</style>

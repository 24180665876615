<template>
  <div>
    <h2>
      {{ appliedFilter }}
    </h2>
  </div>
</template>

<script>
export default {
  name: "CoreFilter",
  props: ["appliedFilter"],
};
</script>

<style scoped></style>

<template>
  <div>
    <p v-if="this.apiData.length === 0" class="center-align">
      No data present. Please search with different term.
    </p>
    <v-table v-else :data="rows" :hideSortIcons="true" class="table-wrapper">
      <thead slot="head">
        <th class="table-head">Status</th>
        <v-th class="table-head" sortKey="container_id"
          ><span>Container ID</span></v-th
        >
        <v-th class="table-head" sortKey="depot_id">Depot ID</v-th>
        <v-th class="table-head" sortKey="damages">Damages</v-th>
        <v-th class="table-head" sortKey="capture_start_time"
          >Captured Date
        </v-th>
        <v-th class="table-head" sortKey="time_taken"
          >Time Taken (seconds)
        </v-th>
        <th class="table-head">Verified</th>
      </thead>
      <tbody slot="body" slot-scope="{ displayData }">
        <tr
          v-for="row in displayData"
          :key="row.length"
          @click="rowSelected(row)"
        >
          <td
            v-for="col in cols"
            :key="col.key"
            :class="{
              'cursor-point': row['container_status'] !== 'in_process',
              'cursor-reset': row['container_status'] === 'in_process',
            }"
            class="table-body"
          >
            <Icon
              v-if="col.key === 'container_status'"
              :name="getIcon(row[col.key])[0]"
              :state="getIcon(row[col.key])[1]"
              size="small"
              type="filled"
            ></Icon>
            <p
              v-else-if="col.key === 'verified'"
              :class="{
                'blue-bg': row[col.key] === 'Pending',
                'red-bg': row[col.key] === 'No',
                'green-bg': row[col.key] === 'Yes',
              }"
              class="row-right"
            >
              {{ row[col.key] }}
            </p>
            <p v-else>{{ row[col.key] }}</p>
          </td>
        </tr>
      </tbody>
    </v-table>

    <!-- <div class="nav-right-foot">
      <span :key="pageOffset">{{ pageOffset + 1 }}-{{ (pageOffset + pageLimit) }}</span>
      <a
        href=""
        :class="{ 'nav-disabled': prevDisabled }"
        @click.prevent="pageRequested('prev')"
        ><img src="../../assets/left.png"
      /></a>
      <a
        href=""
        :class="{ 'nav-disabled': nextDisabled }"
        @click.prevent="pageRequested('next')"
        ><img src="../../assets/right.png"
      /></a>
    </div> -->

    <div :position="position" class="flow-pagination-container min-width-172">
      <Icon
        :disabled="offset <= 0 || isLoading"
        name="i-chevron-left"
        size="small"
        type="filled"
        @click="pageRequested('prev')"
      />
      <p v-if="!isLoading" class="paragraph-1 fc-normal">
        {{ label }}
      </p>
      <Icon v-if="isLoading" name="loading" size="small" />
      <Icon
        :disabled="!nextEnable || isLoading"
        name="i-chevron-right"
        size="small"
        type="filled"
        @click="pageRequested('next')"
      />
    </div>
    <!-- <Pagination
      :state="paginationState"
      position="inherit"
      :max="noRecords"
      :offset.sync="poffset"
      :total="50"
      @next="handleNext(...arguments)"
      @prev="handlePrev(...arguments)"
    /> -->
  </div>
</template>

<script>
import { Icon } from "@cldcvr/flow";
// import Pagination from "./Pagination.vue";
import { mapGetters } from "vuex";
// import SmartTable from 'vuejs-smart-table';

export default {
  name: "TableData",
  props: ["apiData", "limit", "offset", "nextEnable"],
  emits: ["request-pages"],
  components: {
    Icon,
    // SmartTable,
  },
  data() {
    return {
      rows: this.getRows(this.apiData),
      cols: [
        { display: "Status", key: "container_status", sortable: false },
        { display: "Container ID", key: "container_id", sortable: true },
        { display: "Depot ID", key: "depot_id", sortable: true },
        { display: "Damages", key: "damages", sortable: true },
        { display: "Captured Date", key: "capture_start_time", sortable: true },
        { display: "Time Taken (seconds)", key: "time_taken", sortable: true },
        { display: "Verified", key: "verified", sortable: false },
      ],
      current: {},
      paginationState: "primary",
      poffset: 0,
      pageLimit: this.limit,
      pageOffset: this.offset,
      isLoading: false,
      position: "",
      getIconName: "",
      getIconState: "",
    };
  },
  computed: {
    ...mapGetters({
      tableData: "dashboard/getTableData",
      notFoundMsg: "dashboard/getNotFoundMsg",
      selectedRowsCount: "dashboard/getSelectedRowsCount",
      pageSize: "dashboard/getPageSize",
    }),
    noRecords() {
      return this.pageSize;
    },
    label() {
      const firstIndex = this.pageOffset * 50 + 1;
      const lastIndex = (this.pageOffset + 1) * 50;
      return `${firstIndex} - ${lastIndex}`;
    },
  },
  beforeCreate() {
    this.getIconName = "getIcon(row[col.key])[0]";
    this.getIconState = "getIcon(row[col.key])[1]";
  },
  methods: {
    getRows(data) {
      let newData = [];
      newData = data;
      return newData;
    },
    getIcon(item) {
      let newItem = [];
      switch (item) {
        case "in_process":
          newItem = ["i-loader", "success"];
          break;
        case "pending":
          newItem = ["i-history", "primary"];
          break;
        case "no_damages":
          newItem = ["i-tick-double", "success"];
          break;
        case "damages":
          newItem = ["i-alert", "warning"];
          break;
        default:
          newItem = ["i-loader", "success"];
          break;
      }
      return newItem;
    },
    rowSelected(row) {
      // noinspection JSUnresolvedVariable
      if (row.container_status !== "in_process") {
        this.$emit("clicked", row);
      }
    },
    async handleNext(newoffset) {
      this.isLoading = true;
      try {
        await this.$store.dispatch(
          "dashboard/handleNextPage",
          newoffset / this.pageSize
        );
        this.paginationState = "primary";
      } catch (err) {
        console.error(err);
        this.paginationState = "error";
      } finally {
        this.isLoading = false;
      }
    },
    async handlePrev(newoffset) {
      this.isLoading = true;
      try {
        await this.$store.dispatch(
          "dashboard/handlePrevPage",
          newoffset / this.pageSize
        );
        this.paginationState = "primary";
      } catch (err) {
        console.error(err);
        this.paginationState = "error";
      } finally {
        this.isLoading = false;
      }
    },
    pageRequested(str) {
      if (str === "next") {
        this.pageOffset += 1;
      } else {
        this.pageOffset -= 1;
      }
      let params = {
        offset: this.pageOffset,
      };
      this.$emit("request-pages", params);
    },
  },
};
</script>

<style>
.table-wrapper {
  color: white;
  padding: 20px;
  width: 100%;
}

.table-body {
  min-width: 30px;
  padding: 5px 15px;
}

.table-head {
  text-transform: capitalize;
  color: lightsteelblue;
  min-width: 30px;
  padding: 20px;
  cursor: pointer;
}

.center-align {
  text-align: center !important;
  color: white;
  padding: 30px;
}

.vt-sortable:before {
  content: "\25B2";
  padding: 0.5em;
}

.vt-desc:before {
  content: "\25B2";
  padding: 0.5em;
}

.vt-asc::before {
  content: "\25BC";
  padding: 0.5em;
}

.nav-right-foot {
  float: right;
  vertical-align: middle;
}

.nav-right-foot span {
  float: left;
}

.nav-right-foot img {
  height: 12px;
  padding: 2px 10px 0 10px;
}

.nav-disabled {
  cursor: auto;
  pointer-events: none;
  color: #e0deed;
}

div.flow-pagination-container {
  display: flex;
  z-index: 0;
  border-radius: 8px 8px 0px 0px;
  background-color: var(--dashboard-element-light);
  height: 50px;
  align-items: center;
  justify-content: space-between;
  padding: 0px 24px 0px 24px;
  bottom: 0px;
  right: 24px;
}

div.flow-pagination-container > * + * {
  margin-left: 24px;
}

div.flow-pagination-container > * + *.loading-text {
  margin-left: 8px;
}

div.flow-pagination-container[position="fixed"] {
  position: fixed;
}

div.flow-pagination-container[position="absolute"] {
  position: absolute;
}

.min-width-172 {
  min-width: 172px;
}
</style>

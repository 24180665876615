export function toggleSlideout(state, value) {
  state.isSlideoutOpen = value;
}

export function toggleModal(state, value) {
  state.isImageModalOpen = value;
}

export function setTableData(state, tableData) {
  state.tableData = tableData;
}

export function setNotFoundMsg(state, notFoundMsg) {
  state.notFoundMsg = notFoundMsg;
}

export function setPageSize(state, pageSize) {
  state.pageSize = pageSize;
}

export function setStartKey(state, startKey) {
  state.startKey = startKey;
}

export function setSlideoutDetails(state, slideoutDetails) {
  state.slideoutDetails = slideoutDetails;
}

export async function setSelectedImage(state, selectedImage) {
  state.selectedImage = selectedImage;
}

export async function setSearchPayload(state, payload) {
  state.searchPayload = payload;
}

export async function updateSelectedRows(state, count) {
  state.selectedRowsCount = count;
}

export async function toggleIsNextEnable(state, value) {
  state.isNextEnable = value;
}

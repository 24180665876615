<template>
  <div class="padding-20">
    <div class="flex">
      <Button
        :disabled="false"
        :fullWidth="false"
        :loading="false"
        class="secondary-btn long-btn"
        loadingType="background"
        size="normal"
        state="full"
        type="default"
        @click="buttonClicked('total')"
      >
        Total
        <span class="cir">{{ this.containers_numbers.total }}</span>
      </Button>
      <Button
        :disabled="false"
        :fullWidth="false"
        :loading="false"
        class="secondary-btn"
        loadingType="background"
        size="normal"
        state="full"
        type="default"
        @click="buttonClicked('no_damages')"
      >
        <Icon
          :disabled="isLoading"
          name="i-tick-double"
          size="small"
          state="success"
          type="filled"
        >
        </Icon>
        <span class="cir">{{ this.containers_numbers.no_damages }}</span>
      </Button>
      <Button
        :disabled="false"
        :fullWidth="false"
        :loading="false"
        class="secondary-btn"
        loadingType="background"
        size="normal"
        state="full"
        type="default"
        @click="buttonClicked('damages')"
      >
        <Icon
          :disabled="isLoading"
          name="i-alert"
          size="small"
          state="warning"
          type="filled"
        >
        </Icon>
        <span class="cir">{{ this.containers_numbers.damages }}</span>
      </Button>
      <Button
        :disabled="false"
        :fullWidth="false"
        :loading="false"
        class="secondary-btn"
        loadingType="background"
        size="normal"
        state="full"
        type="default"
        @click="buttonClicked('in_process')"
      >
        <Icon
          :disabled="isLoading"
          name="i-loader"
          size="small"
          state="success"
          type="filled"
        >
        </Icon>
        <span class="cir">{{ this.containers_numbers.in_process }}</span>
      </Button>
      <SearchInput
        :placeholder="placeholder"
        :showSearchIcon="true"
        :value.sync="searchParams.text"
      >
      </SearchInput>
      <div class="date-picker">
        <date-picker
          v-model="searchParams.startDate"
          :disabled-date="(date) => date >= new Date()"
          placeholder="YYYY-MM-DD"
          type="date"
        ></date-picker>
      </div>
      <div class="date-picker">
        <date-picker
          v-model="searchParams.endDate"
          :disabled-date="
            (date) => !(date <= new Date() && date >= searchParams.startDate)
          "
          placeholder="YYYY-MM-DD"
          type="date"
        ></date-picker>
      </div>
      <div class="dropdown-selector">
        <v-select
          v-model="searchParams.verified"
          :options="Object.keys(verifiedOptions)"
          :value="searchParams.verified"
          class="dropdown-select"
          placeholder="Select status"
        ></v-select>
      </div>
      <Button
        :disabled="false"
        :fullWidth="false"
        :loading="false"
        class="primary-btn"
        loadingType="background"
        size="normal"
        state="full"
        type="default"
        @click="buttonClicked('search')"
      >
        <Icon
          :disabled="isLoading"
          name="i-search"
          size="small"
          state="default"
          type="filled"
        >
        </Icon>
        <span>Search</span>
      </Button>
      <div class="reset-btn" @click="buttonClicked('reset')">Reset</div>
    </div>
    <Loader v-if="this.isLoading"
      ><p class="paragraph-2 fc-light">loading...</p></Loader
    >
    <TableData
      v-else
      :key="needRefresh"
      :apiData="apiData"
      :limit="pageLimit"
      :nextEnable="nextEnable"
      :offset="offset"
      @clicked="viewDetails"
      @request-pages="requestPages"
    />
  </div>
</template>

<script>
import axios from "axios";
import { Button, Icon, Loader, SearchInput } from "@cldcvr/flow";
import TableData from "../components/containerTable/TableData.vue";
import { mapActions, mapGetters } from "vuex";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import {ENV_SETTINGS} from "@/constants/constants";

export default {
  name: "Home",
  components: {
    SearchInput,
    Icon,
    Button,
    TableData,
    Loader,
    DatePicker,
    vSelect,
  },
  props: ["appliedFilter"],
  data() {
    return {
      pageLimit: 10,
      offset: 0,
      startKeys: [{}],
      nextEnable: false,
      params: {
        container_id: "",
        start_date: "",
        end_date: "",
        start_key: {},
        verified: "all",
        widget: "total",
      },
      searchParams: {
        text: "",
        startDate: "",
        endDate: "",
        verified: "",
      },
      verifiedOptions: {
        All: "all",
        Verified: "Yes",
        "Not Verified": "Pending",
      },
      isLoading: false,
      placeholder: "Search Container ID",
      needRefresh: this.$route.params.needRefresh,
      apiData: [],
      containers_numbers: {
        stats_id: 0,
        images_upload_started: 0,
        no_damages: 0,
        damages: 0,
        pending: 0,
        error_containers: 0,
        total_containers: 0,
        in_process: 0,
        total: 0,
      },
    };
  },
  computed: {
    ...mapGetters({
      tableData: "dashboard/getTableData",
    }),
  },
  // beforeCreate() {
  //   this.params.widget = this.getAppliedFilter();
  // },
  created() {
    // watch the params of the route to fetch the data again
    console.log("🦕");
    this.$watch(
      () => this.$route.params,
      () => {
        this.params.widget = this.getAppliedFilter();
      },
      // fetch the data when the view is created and the data is
      // already being observed
      { immediate: true }
    );
  },

  mounted() {
    this.fetchApiData();
    // setInterval(this.fetchApiData, 600000); // decide the time
  },
  methods: {
    ...mapGetters(["getAppliedFilter"]),
    ...mapActions(["UPDATE_APPLIED_FILTER"]),
    async buttonClicked(e) {
      switch (e) {
        case "search":
          this.resetParams();
          if (this.searchParams.endDate === "") {
            this.searchParams.endDate = new Date();
          }
          this.searchParams.endDate.setDate(
            this.searchParams.endDate.getDate() + 1
          );
          this.params.container_id = this.searchParams.text;
          this.params.start_date = this.searchParams.startDate;
          this.params.end_date = this.searchParams.endDate;
          this.params.verified =
            this.verifiedOptions[this.searchParams.verified];
          // this.resetSearchParams(this.searchParams.verified);
          this.UPDATE_APPLIED_FILTER("total");
          await this.fetchApiData();
          break;
        case "total":
          await this.buttonSwitched("");
          break;
        case "no_damages":
          await this.buttonSwitched(e);
          break;
        case "damages":
          await this.buttonSwitched(e);
          break;
        case "in_process":
          await this.buttonSwitched(e);
          break;
        case "reset":
          try {
            await this.$router.push("/");
            this.resetSearchParams();
          } finally {
            window.location.reload();
          }
          break;
        default:
          await this.fetchApiData();
          break;
      }
    },
    async buttonSwitched(to_navigate_path) {
      if (this.$route.path !== `/${to_navigate_path}`) {
        this.resetParams();
        this.resetSearchParams();
        this.UPDATE_APPLIED_FILTER(
          to_navigate_path === "" ? "total" : to_navigate_path
        );
        await this.$router.push(`/${to_navigate_path}`);
        await this.fetchApiData();
      }
    },

    async fetchApiData() {
      this.isLoading = true;
      const url =`${ENV_SETTINGS.BASE_GATEWAY_URL}/goodpack-th-search-api`;

      axios
        .post(
          // "https://dmntse60f7.execute-api.ap-southeast-1.amazonaws.com/default/goodpack-th-search-api",
          url,
            {
            params: this.params,
          }
        )
        .then((response) => {
          console.log('🦋');
          console.log(response);
          // noinspection JSUnresolvedVariable
          this.apiData = response.data.body.containers_info;
          this.containers_numbers = response.data.body.containers_numbers;
          this.isLoading = false;
          this.nextEnable =
            Object.keys(response.data.body.start_key).length === 1;
          this.startKeys.push(response.data.body.start_key);
        });
    },
    resetFilters() {
      this.resetSearchParams();
      this.resetParams();
      this.fetchApiData();
    },
    resetParams() {
      this.params = {
        container_id: "",
        start_date: "",
        end_date: "",
        start_key: {},
        verified: "all",
        widget: this.getAppliedFilter(),
      };
      this.offset = 0;
      this.startKeys = [{}];
      this.nextEnable = false;
      this.apiData = [];
      this.containers_numbers = {};
    },
    resetSearchParams(selected_verified_value = "All") {
      this.searchParams = {
        text: "",
        startDate: "",
        endDate: "",
        verified: selected_verified_value,
      };
    },
    requestPages(e) {
      this.offset = e.offset;
      this.params.start_key = this.startKeys[this.offset];
      this.fetchApiData();
    },
    viewDetails(e) {
      console.log("🐍", e);
      let routeData = this.$router.resolve({
        name: "Details",
        params: {
          rfid: e.rfid,
          containerId: e.container_id,
          verified: e.verified,
          capture_start_time: e.capture_start_time,
        },
      });
      window.open(routeData.href, "_blank");

      // this.$router.push({
      //   name: "Details",
      //   params: {
      //     rfid: e.rfid,
      //     containerId: e.container_id,
      //     verified: e.verified,
      //   },
      // });
    },
  },
};
</script>

<style>
.flex {
  display: flex;
}

.search-input-container[data-shape="rounded"] {
  margin-left: 50px;
  border-radius: 8px;
  width: -webkit-fill-available;
  max-height: 50px;
  min-width: 200px;
}

.padding-0 {
  padding: 0;
  margin-left: 5px;
}

.padding-10 {
  padding: 10px;
}

.display-none {
  display: none;
}

.primary-btn {
  margin-left: 10px;
  min-width: 120px;
}

.secondary-btn {
  margin-left: 10px;
  min-width: 100px;
  overflow: hidden;
}

.long-btn {
  min-width: 130px;
}

.margin-top-20 {
  margin-top: 20px;
}

.cir {
  display: inline-block;
  border-radius: 60px;
  background-color: rgba(255, 255, 255, 0.1);
  padding: 0.5em 1em;
  margin: 5px;
}

.cursor-point {
  cursor: pointer;
}

.cursor-reset {
  cursor: default;
}

.date-picker {
  height: 50px;
  min-width: 150px;
  background-color: #3a4d64;
  border-radius: 8px;
  margin-left: 10px;
}

.mx-input {
  background-color: #3a4d64;
  color: lightsteelblue;
  box-shadow: none;
  border: none;
}

.mx-input::placeholder {
  color: #697b8f;
}

.mx-icon-calendar {
  color: #ccd6e0;
}

.dropdown-selector {
  height: 50px;
  background-color: #3a4d64;
  border-radius: 8px;
  margin-left: 10px;
}

.dropdown-select {
  color: lightsteelblue;
  --vs-selected-color: #lightsteelblue;
}

.vs__dropdown-toggle {
  width: 150px;
  background: none;
}

.vs__dropdown-option {
  color: #303d4d;
}

.vs__search {
  padding: 0;
}

.vs__search::placeholder {
  color: #697b8f;
}

.vs__open-indicator {
  fill: #ccd6e0;
}

.reset-btn {
  color: white;
  text-decoration: underline;
  width: 50px !important;
  cursor: pointer;
}
</style>

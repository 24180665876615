<template>
  <div id="app" class="home fc-dark">
    <Layout>
      <template #navbar>
        <CoreHeader />
      </template>
      <perfect-scrollbar>
        <router-view />
      </perfect-scrollbar>
    </Layout>
  </div>
</template>
<script>
import { Layout } from "@cldcvr/flow";
import CoreHeader from "./components/core/CoreHeader.vue";

export default {
  name: "App",
  components: {
    Layout,
    CoreHeader,
  },
  // watch: {
  //   $route() {
  //     this.$refs.scroll.$el.scrollTop = 0;
  //   }
  // }
};
</script>
<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

div.flow-layout-topbar {
  height: 90px;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}

.light-mode,
.light-theme {
  --gray-300: #445973;
  --primary-500: #576775;
  --success-300: #69c36c;
  --danger-500: #8f0a0a;
  --primary-300: #509e2f !important;
  --success-500: #174f19;
  --gray-200: #697b8f;
  --white: #000;
  --secondary-400: #9000ff;
  --gray-400: #d6dde6;
  --warning-100: #ffedcc;
  --black: #fff;
  --warning-500: #654101;
  --gray-700: #cfd2d8;
  --gray-800: #ccd6e0;
  --gray-900: #2a3746;
  --secondary-500: #685775;
  --gray-100: #303d4d;
  --gray-600: #fff;
  --gray-500: #f0f1f6;
}

.align-text-left {
  text-align: left;
}
</style>

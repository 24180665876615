export async function onSearch() {
  // later
}

// export async function handleNextPage({ commit, state }, offsetIndex) {
// }

// export async function handlePrevPage({ commit, state }, offsetIndex) {
// }

export function toggleIsNextEnable({ commit }, value) {
  commit("toggleIsNextEnable", value);
}

// export async function handleNextPage({ commit, state }, offsetIndex) {
//   const payload = state.searchPayload;
//   const startKeys = state.startKey;
//   payload["start_key"] = startKeys[offsetIndex];
//   const res = await apiService.post("/search-metadata", payload);
//   const { start_key, results } = res.data;
//   commit("setTableData", results);
//   if (Object.keys(start_key).length === 0) commit("togleIsNextEnable", false);
//   startKeys.push(start_key);
//   await commit("setStartKey", startKeys);
// }

// export async function handlePrevPage({ commit, state }, offsetIndex) {
//   const payload = state.searchPayload;
//   const startKeys = state.startKey;
//   payload["start_key"] = startKeys[offsetIndex];
//   const res = await apiService.post("/search-metadata", payload);
//   const { results } = res.data;
//   commit("togleIsNextEnable", true);

//   commit("setTableData", results);
// }

import Vue from "vue";
import Vuex from "vuex";
import dashboard from "./dashboard";
import filter from "@/store/dashboard/filter";

Vue.use(Vuex);

export const store = new Vuex.Store({
  modules: {
    dashboard,
    filter,
  },

  // for dev mode only
  strict: process.env.DEV,
});

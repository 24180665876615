<template>
  <div v-if="!isImagesEmpty" class="grid-container">
    <div v-for="im in images" :key="images[im]" class="grid-item">
      <img
        :src="im"
        class="image-size"
        contain
        @click="showSingle(im)"
        alt="some image"
      />
    </div>
    <perfect-scrollbar>
      <VueEasyLightbox
        :imgs="imgs"
        :visible="visible"
        @hide="handleHide"
      ></VueEasyLightbox>
    </perfect-scrollbar>
  </div>
</template>

<script>
import VueEasyLightbox from "vue-easy-lightbox";

export default {
  name: "RenderImages",
  components: { VueEasyLightbox },
  props: ["images"],
  data() {
    return {
      imgs: "",
      visible: false,
      isImagesEmpty:
        this.images.filter((item) => item === undefined).length !== 0,
    };
  },
  methods: {
    showSingle(img) {
      this.imgs = img;
      this.show();
    },
    show() {
      this.visible = true;
    },
    handleHide() {
      this.visible = false;
    },
  },
};
</script>

<style>
.grid-container {
  display: grid;
  gap: 30px 20px;
  grid-template-columns: 400px 400px;
  padding: 10px;
}

.grid-item {
  background-color: rgba(102, 102, 102, 0.192);
  padding: 15px;
  font-size: 20px;
  text-align: center;
  height: 330px;
  width: 380px;
}

.image-size {
  height: 300px;
  width: 350px;
}
</style>

//* StateManagement for FILTER
export default {
  state: {
    applied_filter: "total",
  },
  getters: {
    getAppliedFilter: (state) => {
      return state.applied_filter.toString();
    },
  },
  mutations: {
    setAppliedFilter: (state, new_applied_filter) => {
      state.applied_filter = new_applied_filter;
    },
  },
  actions: {
    UPDATE_APPLIED_FILTER: ({ commit }, new_applied_filter) => {
      commit("setAppliedFilter", new_applied_filter);
    },
  },
};

import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Details from "../views/Details.vue";
import CoreFilter from "@/components/core/CoreFilter";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: {
      title: "Dashboard",
    },
  },
  {
    path: "/filter",
    name: "Filter",
    component: CoreFilter,
    meta: {
      title: "Filter",
    },
    props: { appliedFilter: "akshat" },
  },
  {
    path: "/damages",
    name: "Damages",
    component: Home,
    meta: {
      title: "Damages",
    },
    props: { appliedFilter: "damages" },
  },
  {
    path: "/no_damages",
    name: "No_damages",
    component: Home,
    meta: {
      title: "No_damages",
    },
    props: { appliedFilter: "no_damages" },
  },
  {
    path: "/in_process",
    name: "In_process",
    component: Home,
    meta: {
      title: "In_process",
    },
    props: { appliedFilter: "in_process" },
  },
  {
    path: "/details/:verified/:containerId/:capture_start_time",
    name: "Details",
    component: Details,
    meta: {
      title: "Details",
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;

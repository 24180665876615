/// For prod put IS_DEV to false
/// For dev put IS_DEV to true
export const IS_DEV = false;

// New Dev Environment User Pool & Gateway URl Configuration settings
const DEV_SETTINGS = {
    BASE_GATEWAY_URL:
        "https://dmntse60f7.execute-api.ap-southeast-1.amazonaws.com/default",
};

// New Prod Environment User Pool Configuration & Gateway URl Configuration settings
const PROD_SETTINGS = {
    BASE_GATEWAY_URL:
        "https://dmntse60f7.execute-api.ap-southeast-1.amazonaws.com/prod",
};

export const ENV_SETTINGS = IS_DEV ? DEV_SETTINGS : PROD_SETTINGS;
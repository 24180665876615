var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"details-container"},[_c('div',{staticClass:"topnav"},[_c('Typography',{attrs:{"color":"light","type":"h3","weight":"regular"}},[_vm._v(" ContainerId: "),_c('span',{staticClass:"label-value"},[_vm._v(_vm._s(_vm.containerData.containerId))])])],1),(this.isLoading)?_c('Loader',{attrs:{"position":"relative"}},[_c('p',{staticClass:"paragraph-2 fc-light"},[_vm._v("loading...")])]):_c('div',{staticClass:"wrapper"},[_c('div',{key:_vm.needRefresh,staticClass:"col"},[_c('div',{staticClass:"subheader"},[_c('Typography',{attrs:{"color":"warning","transform":"uppercase","type":"h4","weight":"bold"}},[_vm._v("Damages Detected "+_vm._s(_vm.damageData.damageCount)+" ")])],1),_c('table',{staticClass:"full-width"},_vm._l((_vm.damageData.damageList),function(damageObject){return _c('tr',{key:damageObject.status + damageObject.id,class:{
            'selected-container': damageObject.id === _vm.selectedDamage,
          },on:{"click":function($event){return _vm.getDetails(damageObject)}}},[_c('div',{staticClass:"damage-row cursor-point truncate"},[_c('h4',{staticClass:"row-left"},[_vm._v(_vm._s(damageObject.damageName))]),_c('p',{staticClass:"row-right",class:{
                'blue-bg': damageObject.status === 'pending',
                'red-bg':
                  damageObject.status === 'no' ||
                  damageObject.status === 'deleted',
                'green-bg':
                  damageObject.status === 'yes' ||
                  damageObject.status === 'save',
              }},[_vm._v(" "+_vm._s(damageObject.status === "save" ? "yes" : damageObject.status)+" ")]),_c('div',{staticStyle:{"clear":"both"}})])])}),0),_c('div',{staticClass:"subheader"},[_c('Typography',{attrs:{"color":"primary","transform":"uppercase","type":"h4","weight":"bold"}},[_vm._v("Damages Reported "+_vm._s(_vm.damageData.reportedDamageCount)+" ")])],1),_c('table',{staticClass:"full-width"},[_vm._l((_vm.damageData.reportedDamageList),function(reportedDamageOb){return _c('tr',{key:reportedDamageOb.status + reportedDamageOb.id,class:{
            'selected-container': reportedDamageOb.id === _vm.selectedDamage,
          },on:{"click":function($event){return _vm.getDetails(reportedDamageOb)}}},[_c('div',{staticClass:"damage-row cursor-point truncate"},[_c('h4',{staticClass:"row-left"},[_vm._v(_vm._s(reportedDamageOb.damageName))]),_c('div',{staticStyle:{"clear":"both"}})])])}),_c('tr',[(_vm.verified === 'Pending')?_c('div',{staticClass:"damage-row cursor-point",on:{"click":function($event){return _vm.addNewInput()}}},[_c('h4',{staticClass:"row-left"},[_vm._v("Add New")]),_c('p',{staticClass:"row-right grey-bg"},[_vm._v("Add")]),_c('div',{staticStyle:{"clear":"both"}})]):_vm._e()])],2),_c('button',{key:_vm.verified,staticClass:"button-bottom green-bg cursor-point btn disabled",attrs:{"disabled":_vm.isAllVerified},on:{"click":_vm.onClickSave}},[_vm._v(" Save and Confirm ")])]),_c('div',{staticClass:"col"},[(this.isSubLoading)?_c('Loader',{attrs:{"position":"relative"}},[_c('p',{staticClass:"paragraph-2 fc-light"},[_vm._v("loading...")])]):_vm._e(),(
          (_vm.current.component && _vm.current.subcomponent && _vm.current.damageCode) ||
          this.current.name === 'New Damage'
        )?_c('SubDetails',{key:_vm.renderChanges,attrs:{"current":_vm.current,"editable":_vm.editable,"hideButtons":_vm.isHideButtons},on:{"requestedEdit":_vm.requestedEdit}}):_vm._e()],1),_c('div',{staticClass:"col"},[(_vm.current.component && _vm.current.subcomponent && _vm.current.damageCode)?_c('RenderImages',{key:_vm.renderChanges,attrs:{"images":_vm.current.images}}):_vm._e()],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
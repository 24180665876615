<template>
  <div class="details-container">
    <div class="topnav">
      <Typography color="light" type="h3" weight="regular">
        ContainerId:
        <span class="label-value">{{ containerData.containerId }}</span>
      </Typography>
    </div>
    <Loader v-if="this.isLoading" position="relative"
      ><p class="paragraph-2 fc-light">loading...</p></Loader
    >
    <div v-else class="wrapper">
      <div :key="needRefresh" class="col">
        <div class="subheader">
          <Typography
            color="warning"
            transform="uppercase"
            type="h4"
            weight="bold"
            >Damages Detected {{ damageData.damageCount }}
          </Typography>
        </div>
        <table class="full-width">
          <tr
            v-for="damageObject in damageData.damageList"
            :key="damageObject.status + damageObject.id"
            :class="{
              'selected-container': damageObject.id === selectedDamage,
            }"
            @click="getDetails(damageObject)"
          >
            <div class="damage-row cursor-point truncate">
              <h4 class="row-left">{{ damageObject.damageName }}</h4>
              <p
                :class="{
                  'blue-bg': damageObject.status === 'pending',
                  'red-bg':
                    damageObject.status === 'no' ||
                    damageObject.status === 'deleted',
                  'green-bg':
                    damageObject.status === 'yes' ||
                    damageObject.status === 'save',
                }"
                class="row-right"
              >
                {{
                  damageObject.status === "save" ? "yes" : damageObject.status
                }}
              </p>
              <div style="clear: both"></div>
            </div>
          </tr>
        </table>
        <div class="subheader">
          <Typography
            color="primary"
            transform="uppercase"
            type="h4"
            weight="bold"
            >Damages Reported {{ damageData.reportedDamageCount }}
          </Typography>
        </div>
        <table class="full-width">
          <tr
            v-for="reportedDamageOb in damageData.reportedDamageList"
            :key="reportedDamageOb.status + reportedDamageOb.id"
            :class="{
              'selected-container': reportedDamageOb.id === selectedDamage,
            }"
            @click="getDetails(reportedDamageOb)"
          >
            <div class="damage-row cursor-point truncate">
              <h4 class="row-left">{{ reportedDamageOb.damageName }}</h4>
              <div style="clear: both"></div>
            </div>
          </tr>
          <tr>
            <div
              v-if="verified === 'Pending'"
              class="damage-row cursor-point"
              @click="addNewInput()"
            >
              <h4 class="row-left">Add New</h4>
              <p class="row-right grey-bg">Add</p>
              <div style="clear: both"></div>
            </div>
          </tr>
        </table>
        <button
          :key="verified"
          :disabled="isAllVerified"
          class="button-bottom green-bg cursor-point btn disabled"
          @click="onClickSave"
        >
          Save and Confirm
        </button>
      </div>
      <div class="col">
        <Loader v-if="this.isSubLoading" position="relative"
          ><p class="paragraph-2 fc-light">loading...</p></Loader
        >
        <SubDetails
          v-if="
            (current.component && current.subcomponent && current.damageCode) ||
            this.current.name === 'New Damage'
          "
          :key="renderChanges"
          :current="current"
          :editable="editable"
          :hideButtons="isHideButtons"
          @requestedEdit="requestedEdit"
        />
      </div>
      <div class="col">
        <RenderImages
          v-if="current.component && current.subcomponent && current.damageCode"
          :key="renderChanges"
          :images="current.images"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { Loader, Typography } from "@cldcvr/flow";
import SubDetails from "../components/details/SubDetails.vue";
import RenderImages from "../components/details/RenderImages.vue";
import axios from "axios";
import {ENV_SETTINGS} from "@/constants/constants";

export default {
  name: "Details",
  components: {
    Typography,
    SubDetails,
    RenderImages,
    Loader,
  },
  props: [],
  data: function () {
    return {
      renderChanges: 0,
      needRefresh: 0,
      addNew: false,
      editable: false,
      isHideButtons: false,
      isLoading: false,
      isSubLoading: false,
      verified: "Pending",
      selectedDamage: "-1",
      current: {
        id: 0,
        name: "damage",
        damageName: "",
        identifiedTime: "",
        component: "",
        subcomponent: "",
        damageCode: "",
        images: ["", "", "", ""],
        status: "pending",
        lastStatus: "pending",
      },
      containerData: {
        rfid: this.$route.params.rfid,
        containerId: this.$route.params.containerId,
        vvvv: this.$route.params.verified,
        capture_start_time: this.$route.params.capture_start_time,
      },
      damageData: {
        damageList: [],
        damageCount: 0,
        reportedDamageList: [],
        reportedDamageCount: 0,
      },
    };
  },
  computed: {
    isAllVerified() {
      let a = this.damageData.damageList.filter(function (e) {
        return e.status === "pending" || e.status === "no";
      });
      return a.length !== 0;
    },
  },
  methods: {
    async fetchDamages(containerId, capture_start_time) {
      this.isLoading = true;
      const url =`${ENV_SETTINGS.BASE_GATEWAY_URL}/goodpack-th-list-damages?container_id=${containerId}&capture_start_time=${capture_start_time}`;

      return await axios
        .get(
          // `https://dmntse60f7.execute-api.ap-southeast-1.amazonaws.com/default/goodpack-th-list-damages?container_id=${containerId}&capture_start_time=${capture_start_time}`
            url
        )
        .then((response) => {
          this.getDamageList(response.data);
          if (response.data.length > 0) {
            this.containerData.rfid = response.data[0]["rfid"];
            this.containerData.containerId = response.data[0]["container_id"];
          }
          this.checkVerified();
        });
    },
    getDamageList(results) {
      let aiList = [];
      let opList = [];
      for (let i = 0; i < results.length; i++) {
        let item = {
          id: i,
          name: "",
          damageName: results[i]["damage_name"],
          rfid: results[i]["rfid"],
          containerId: results[i]["container_id"],
          status: results[i]["verified"],
          lastStatus: results[i]["verified"],
          identifiedTime: results[i]["damage_identified_time"],
          source: results[i]["damage_source"],
        };
        if (results[i]["damage_source"] === "operator") {
          item.name = "Reported Damage " + (opList.length + 1);
          opList.push(item);
        } else {
          item.name = "Damage " + (aiList.length + 1);
          aiList.push(item);
        }
      }
      this.damageData.damageList = aiList;
      this.damageData.damageCount = aiList.length;
      this.damageData.reportedDamageList = opList;
      this.damageData.reportedDamageCount = opList.length;
      this.isLoading = false;
    },
    getDetails(damageObject, subloading = true) {
      this.editable = false;
      this.isHideButtons = false;
      this.isSubLoading = subloading;
      this.selectedDamage = damageObject.id;

      this.fetchDetails(damageObject);
      this.renderChanges += 1;
    },
    urlReplace(s) {
      // this change is done on 17-Oct-2022 as a security measure by
      // dev: Akshat Tamrakar
      // devOps: Pankaj Bhajwani
      // DataScience: Roshan Dadlaney
      if (s) {
        return s.replace(
          "https://gp-damaged-images.s3.ap-southeast-1.amazonaws.com",
          "https://www.aidamagescan.goodpack.com/images"
        );
      }
    },
    async fetchDetails(damageObject) {
      return await axios
        .get(
          `${ENV_SETTINGS.BASE_GATEWAY_URL}/goodpack-th-damage-details`,
          {
            params: {
              container_id: damageObject.containerId,
              damage_identified_time: damageObject.identifiedTime,
            },
          }
        )
        .then((response) => {
          this.isSubLoading = false;
          this.current.id = damageObject.id;
          this.current.name = damageObject.name;
          this.current.damageName = damageObject.damageName;
          this.current.status = response.data["verified"];
          this.current.lastStatus = damageObject.lastStatus;
          this.current.component = response.data["damage_component"];
          this.current.subcomponent = response.data["damage_subcomponent"];
          this.current.damageCode = response.data["damage_code"];
          this.current.identifiedTime = response.data["damage_identified_time"];
          this.current.images = [
            this.urlReplace(response.data["container_id_image"]),
            this.urlReplace(response.data["full_res_annotated_image"]),
            this.urlReplace(response.data["medium_zoom_annotated_image"]),
            this.urlReplace(response.data["super_zoom_image"]),
          ];
          this.renderChanges += 1;
        });
    },
    requestedEdit: function (updates) {
      this.editable = updates.editable;
      this.current.status = updates.status;
      this.current.lastStatus = updates.lastStatus;
      if (this.current.name !== "New Damage") {
        // do nothing
      } else {
        let itemToChange =
          this.damageData.damageList[
            this.damageData.damageList.findIndex(
              (x) => x.id === this.current.id
            )
          ] ||
          this.damageData.reportedDamageList[
            this.damageData.reportedDamageList.findIndex(
              (x) => x.id === this.current.id
            )
          ];
        itemToChange["status"] = updates.status;
        itemToChange["lastStatus"] = updates.lastStatus;
      }
      this.checkAndPostUpdates();
      this.isHideButtons = !updates.editable;
      this.renderChanges += 1;
      this.checkVerified();
      if (!updates.editable) {
        this.showUpdates(updates);
      }
    },
    checkVerified() {
      // if (
      //   this.damageData.damageList.filter(function (e) {
      //     return e.status === "pending" || e.status === "no";
      //   }).length == 0
      // ) {
      //   this.verified = "Yes";
      // }
    },
    addNewInput() {
      this.selectedDamage = "-1";
      this.addNew = true;
      this.editable = true;
      this.current.id = this.damageData.reportedDamageCount + 1;
      this.current.name = "New Damage";
      this.current.damageName = "New Damage";
      this.isHideButtons = false;
      this.current.status = "new";
      this.renderChanges += 1;
    },
    showUpdates: function (updates) {
      this.editable = false;
      this.current.status = updates.status;
      this.current.id = updates.id;
      this.current.component = updates.component;
      this.current.subcomponent = updates.subcomponent;
      this.current.damageCode = updates.damageCode;
      this.current.images = updates.images;
      this.renderChanges += 1;
    },
    checkAndPostUpdates() {
      let params = {};
      switch (this.current.status) {
        case "yes":
          params = {
            container_id: this.containerData.containerId,
            damage_identified_time: this.current.identifiedTime,
            verified: "yes",
          };
          this.postUpdatetoApi(params);
          break;
        case "save":
          params = {
            container_id: this.containerData.containerId,
            rfid: this.containerData.rfid,
            verified: "yes",
            operator_damage_component: this.current.component,
            operator_damage_subcomponent: this.current.subcomponent,
            operator_damage_code: this.current.damageCode,
            damage_source: "operator",
          };
          if (this.addNew) {
            params["is_new"] = "true";
          } else {
            params["damage_identified_time"] = this.current.identifiedTime;
          }
          this.postUpdatetoApi(params);
          break;
        case "deleted":
          params = {
            container_id: this.containerData.containerId,
            damage_identified_time: this.current.identifiedTime,
            is_deleted: "true",
          };
          this.postUpdatetoApi(params);
          break;
        default:
          break;
      }
    },
    onClickSave: function () {
      this.postConfirmToApi();
      this.verified = "Yes";
      this.$router.push({
        name: "Home",
        params: {
          needRefresh: true,
        },
      });
    },
    onClickBack: function () {
      this.$router.back();
    },
    async postUpdatetoApi(params) {
      const url =`${ENV_SETTINGS.BASE_GATEWAY_URL}/goodpack-th-confirm-single-damage`;
      return axios
        .post(
          // "https://dmntse60f7.execute-api.ap-southeast-1.amazonaws.com/default/goodpack-th-confirm-single-damage",
            url,
            params
        )
        .then(() => {
          this.fetchDamages(this.containerData.containerId, this.containerData.capture_start_time);
          this.needRefresh += 1;
        });
    },
    async postConfirmToApi() {
      const url =`${ENV_SETTINGS.BASE_GATEWAY_URL}/goodpack-th-confirm-single-container`;
      return axios.post(
        // "https://dmntse60f7.execute-api.ap-southeast-1.amazonaws.com/default/goodpack-th-confirm-single-container",
          url,
          {
          container_id: this.containerData.containerId,
          verified: "Yes",
        }
      );
    },
  },
  created() {
    this.verified = this.$route.params.verified;
    this.fetchDamages(this.$route.params.containerId, this.containerData.capture_start_time);
  },
};
</script>

<style>
.details-container {
  height: 80vh;
  background-color: rgba(255, 255, 255, 0.02);
  min-width: 1200px;
  overflow: scroll;
}

.selected-container {
  background-color: blue;
}

.topnav {
  padding: 15px 20px 5px 20px;
  text-align: left;
}

.label-value {
  color: white;
  font-weight: bold;
}

.wrapper {
  display: grid;
  grid-template-columns: 20% 20% 60%;
  grid-column-gap: 2px;
  color: white;
  padding: 10px;
}

.col {
  border-top: 1px solid rgba(0, 0, 0, 0.05);
  background-color: rgba(0, 0, 0, 0.05);
  padding: 15px;
  font-size: 30px;
  height: 100vh;
}

.full-width {
  width: 100%;
}

.subheader {
  text-align: left;
  padding: 15px 0;
}

.damage-row {
  padding: 10px 0;
  border-bottom: 0.5px solid rgba(255, 255, 255, 0.05);
  display: block;
}

.row-left {
  float: left;
  color: lightsteelblue;
}

.row-right {
  float: right;
  padding: 1px 15px;
  font-size: small;
  border-radius: 3px;
  font-weight: bold;
}

.truncate {
  /* width: 250px; */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.button-bottom {
  border: none;
  padding: 15px 20px;
  display: flex;
  /*position: absolute;*/
  bottom: 10px;
  font-size: 1rem;
  font-weight: bold;
  justify-content: center;
  align-self: center;
  width: 15%;
  /* width: inherit; */
  min-width: 200px;
  color: white;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: auto;
  margin-right: auto;
}

.button-rounded {
  border-radius: 20px;
  border: none;
  padding: 10px 80px;
  text-align: center;
  font-weight: bold;
  display: inline-block;
  font-size: 16px;
  margin: 15px 0;
  color: white;
}

.btn.disabled {
  color: rgb(161, 161, 161);
}

.right-nav-btn {
  float: right;
  font-weight: bold;
  font-size: 0.9em;
}

.green-bg {
  background-color: rgb(23, 105, 21);
}

.blue-bg {
  background-color: rgb(18, 72, 172);
}

.red-bg {
  background-color: rgb(185, 72, 72);
}

.grey-bg {
  background-color: rgb(86, 97, 117);
}
</style>

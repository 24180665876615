<template>
  <div>
    <button
      v-if="isYesVisible && !editable"
      class="green-bg button-rounded cursor-point"
      value="yes"
      @click="onClick($event)"
    >
      Yes
    </button>
    <button
      v-if="isNoVisible && !editable"
      class="red-bg button-rounded cursor-point"
      value="no"
      @click="onClick($event)"
    >
      No
    </button>
    <button
      v-if="isSaveVisible"
      :disabled="!isEnableSave"
      class="green-bg button-rounded cursor-point btn disabled"
      value="save"
      @click="onClick($event)"
    >
      Save
    </button>
  </div>
</template>

<script>
export default {
  name: "RenderButtons",
  props: ["editable", "enableSave"],
  data: function () {
    return {
      isEnableSave: this.enableSave,
      isEditable: this.editable,
      isYesVisible: !this.editable,
      isNoVisible: !this.editable,
      isSaveVisible: this.editable,
    };
  },
  methods: {
    onClick: function (btnClick) {
      switch (btnClick.target.value) {
        case "yes":
          this.isYesVisible = false;
          this.isNoVisible = false;
          break;
        case "no":
          this.isYesVisible = false;
          this.isNoVisible = false;
          this.isSaveVisible = true;
          this.isEditable = true;
          break;
        case "save":
          this.isYesVisible = false;
          this.isNoVisible = false;
          this.isSaveVisible = false;
          this.isEditable = false;
          break;
        default:
        // do nothing
      }
      this.$emit("clicked", btnClick.target.value);
    },
  },
};
</script>

<style></style>

import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import { store } from "./store";
import SmartTable from "vuejs-smart-table";
import PerfectScrollbar from "vue2-perfect-scrollbar";
import "vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css";

Vue.use(SmartTable);
Vue.use(PerfectScrollbar);
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");

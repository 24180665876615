<template>
  <div>
    <ul>
      <li class="label">
        Component
        <div class="dropdown">
          <select
            v-model="selectedComponent"
            :disabled="this.selectedComponent !== 'null'"
            class="dropbtn"
            @change="select('component')"
          >
            <option
              v-if="this.selectedComponent !== 'null'"
              :value="this.selectedComponent"
              disabled
            >
              {{ this.selectedComponent }}
            </option>
            <option v-else :value="null" disabled>Select</option>
            <option
              v-for="item in componentsData"
              :key="item.name"
              :value="item.name"
              class="dropdown-content"
            >
              {{ item.name }}
            </option>
          </select>
        </div>
      </li>
      <li class="label">
        Subcomponent
        <div class="dropdown">
          <select
            v-model="selectedSubcomponent"
            :disabled="this.selectedComponent === 'null'"
            class="dropbtn"
            @change="select('subcomponent')"
          >
            <option
              v-if="this.selectedSubcomponent !== 'null'"
              :value="this.selectedSubcomponent"
              disabled
            >
              {{ this.selectedSubcomponent }}
            </option>
            <option v-else :value="null" disabled>Select</option>
            <option
              v-for="item in Object.keys(subcomponentsData)"
              :key="item"
              :value="item"
              class="dropdown-content"
            >
              {{ item }}
            </option>
          </select>
        </div>
      </li>
      <li class="label">
        Damage Type
        <div class="dropdown">
          <select
            v-model="selectedDamageCode"
            :disabled="this.selectedSubcomponent === 'null'"
            class="dropbtn"
            @change="select('damageCode')"
          >
            <option
              v-if="this.selectedDamageCode !== 'null'"
              :value="this.selectedDamageCode"
              disabled
            >
              {{ this.selectedDamageCode }}
            </option>
            <option v-else :value="null" disabled>Select</option>
            <option
              v-for="item in damageCodeArr"
              :key="item"
              :value="item"
              class="dropdown-content"
            >
              {{ item }}
            </option>
          </select>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import json from "@/constants/damage-component-data.json";

export default {
  name: "InputDetails",
  data: function () {
    return {
      selectedComponent: this.name == "New Damage" ? "null" : this.component,
      selectedSubcomponent:
        this.name == "New Damage" ? "null" : this.subcomponent,
      selectedDamageCode: this.name == "New Damage" ? "null" : this.damageCode,
      componentsData: json.components,
      subcomponentsData: {},
      damageCodeArr: [],
    };
  },
  props: ["name", "component", "subcomponent", "damageCode"],
  methods: {
    select: function (item) {
      const selection = {
        selectedComponent: this.selectedComponent,
        selectedSubcomponent: this.selectedSubcomponent,
        selectedDamageCode: this.selectedDamageCode,
      };
      switch (item) {
        case "component":
          this.subcomponentsData = this.componentsData.filter(
            (d) => d.name === this.selectedComponent
          )[0].sub;
          break;
        case "subcomponent":
          this.damageCodeArr =
            this.subcomponentsData[this.selectedSubcomponent];
          break;
        default:
          break;
      }
      if (
        this.selectedComponent != "null" &&
        this.selectedSubcomponent != "null" &&
        this.selectedDamageCode != "null"
      ) {
        this.$emit("selected", selection);
      }
    },
  },
  mounted() {
    if (
      this.selectedComponent !== "null" &&
      this.selectedSubcomponent !== "null" &&
      this.selectedDamageCode !== "null"
    ) {
      this.subcomponentsData = this.componentsData.filter(
        (d) => d.name === this.selectedComponent
      )[0].sub;
      this.damageCodeArr = this.subcomponentsData[this.selectedSubcomponent];
    }
  },
};
</script>

<style>
.dropdown {
  position: relative;
  display: block;
}

.dropbtn {
  color: white;
  cursor: pointer;
  font-size: 1em;
  background-color: rgba(142, 191, 255, 0.096);
  font-weight: bold;
  padding: 12px;
  padding-left: 15px;
  width: 200px;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-content a:hover {
  background-color: #f1f1f1;
}

.dropdown:hover .dropdown-content {
  display: block;
}
</style>

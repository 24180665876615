<template>
  <div>
    <div class="sub-header blue-bg">{{ this.current.damageName }}</div>
    <RenderDetails
      v-if="!isEditable"
      :key="detailsUpdate"
      :component="this.current.component"
      :damageCode="this.current.damageCode"
      :subcomponent="this.current.subcomponent"
    />
    <InputDetails
      v-if="isEditable"
      :key="detailsUpdate"
      :component="this.current.component"
      :damageCode="this.current.damageCode"
      :name="this.current.name"
      :subcomponent="this.current.subcomponent"
      @selected="selected"
    />
    <RenderButtons
      v-if="!hideButtons && this.currentDetails.status !== 'yes'"
      :key="buttonUpdate"
      :editable="isEditable"
      :enableSave="enableSave"
      @clicked="requestedEdit"
    />
    <button
      class="button-bottom cursor-point grey-bg"
      @click="onClickCancel($event)"
    >
      {{ isDeletable ? "Delete" : "Cancel" }}
    </button>
  </div>
</template>

<script>
import RenderDetails from "./RenderDetails.vue";
import InputDetails from "./InputDetails.vue";
import RenderButtons from "./RenderButtons.vue";
import Swal from "sweetalert2";

export default {
  name: "SubDetails",
  props: ["current", "hideButtons", "editable", "images"],
  components: {
    RenderDetails,
    InputDetails,
    RenderButtons,
  },
  data: function () {
    return {
      buttonUpdate: 0,
      detailsUpdate: 10,
      deleteUpdate: 100,
      enableSave: false,
      isEditable: this.editable,
      currentDetails: this.current,
      isDeletable: !this.editable,
      deleting: false,
    };
  },
  methods: {
    requestedEdit: function (val) {
      this.isEditable = val === "no";
      this.buttonUpdate += 1;
      this.detailsUpdate += 1;
      this.currentDetails.editable = val === "no";
      this.currentDetails.isDeletable = val === "no";
      this.currentDetails.lastStatus = val === "no" ? this.status : "";
      this.currentDetails.status = val;
      this.$emit("requestedEdit", this.currentDetails);
    },
    selected: function (e) {
      this.currentDetails.component = e.selectedComponent;
      this.currentDetails.subcomponent = e.selectedSubcomponent;
      this.currentDetails.damageCode = e.selectedDamageCode;
      this.currentDetails.images = this.images || [];
      this.enableSave = true;
      this.buttonUpdate += 1;
    },
    onClickCancel: function () {
      if (this.isDeletable) {
        this.deleting = true;
        this.deleteUpdate += 1;
        Swal.fire({
          title: "Confirm Damage Delete",
          text: "Do you want to delete this damage? This action cannot be reversed.",
          showCancelButton: true,
          position: "center",
          padding: "0 0 20px 0",
          color: "white",
          background: "rgba(255, 255, 255, 0.1)",
          confirmButtonColor: "rgb(255, 85, 85)",
          cancelButtonColor: "rgb(120, 134, 161)",
          confirmButtonText: "Delete",
        }).then((result) => {
          if (result.isConfirmed) {
            this.currentDetails.status = "deleted";
            this.currentDetails.editable = false;
            this.editable = false;
            this.detailsUpdate += 1;
            this.$emit("requestedEdit", this.currentDetails);
            // commenting below line of code fixed delete button click behivious
            // window.location.reload();
          }
        });
      } else {
        // editing existing or creating new, cancel to stop op and return to previous state
        this.isEditable = false;
        this.currentDetails.editable = false;
        this.currentDetails.status = this.lastStatus;
        if (this.name === "New Damage") {
          this.currentDetails = {};
        }
        this.detailsUpdate += 1;
        this.$emit("requestedEdit", this.currentDetails);
      }
    },
  },
};
</script>

<style>
.sub-header {
  color: white;
  text-align: left;
  padding: 3px 10px;
}

.swal2-popup {
  font-size: 0.7rem !important;
}
</style>
